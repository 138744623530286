import { config } from '../constants';
import { Meta } from '../types/meta';
import { Filters } from '../types/filters';
import axios from 'axios';
import qs from 'qs';
import { Sort } from '../types/sort';

export class BackendApi<T> {
  constructor(private endPoint: string) {
    this.endPoint = endPoint;
  }

  find(pageMeta?: Meta, filters?: Filters[], populate?: string, sorting?: Sort[]): Promise<{ data: T[], meta?: Meta }> {
    const accessToken = window.localStorage.getItem('accessToken');
    const baseEndpoint = `${config.API_URL}/${this.endPoint}`;

    const queryParamsArray = [];

    if (pageMeta) {
      if (pageMeta.pagination) {
        for (const key in pageMeta.pagination) {
          if (pageMeta.pagination.hasOwnProperty(key) && pageMeta.pagination[key] !== undefined) {
            queryParamsArray.push(`pagination[${key}]=${pageMeta.pagination[key]}`);
          }
        }
      }
    }

    if (filters && filters.length > 0) {
      const filtersString = qs.stringify(
        filters.reduce((acc, filter, index) => {
          const { field, operator, value, combining } = filter;
          let combiningKey = '';

          if (combining !== undefined) {
            combiningKey = `[${combining}]`;
          }

          const filterKey = `filters${combiningKey}[${index}][${field}][${operator}]`; return { ...acc, [filterKey]: value };
        }, {}),
        { encode: false }
      );
      queryParamsArray.push(filtersString);
    }

    if (sorting && sorting.length > 0) {
      const sortingString = qs.stringify(
        sorting.reduce((acc, sort, index) => {
          const { field, order } = sort;
          const sortString = `${field}${order}`;
          return { ...acc, [`sort[${index}]`]: sortString };
        }, {}),
        { encode: false }
      );
      queryParamsArray.push(sortingString);
    }

    if (populate) {
      queryParamsArray.push(`${populate}`);
    }

    const queryParams = queryParamsArray.join('&');
    const url = `${baseEndpoint}${queryParams ? `?${queryParams}` : ''}`;

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then((response) => {
          resolve({ data: response.data.data, meta: response.data.meta });
        })
        .catch((error) => {
          console.error(`[${this.endPoint} Api]: `, error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  async findOne({ id }): Promise<T> {
    const accessToken = window.localStorage.getItem('accessToken');

    let populateString = '?populate=deep,4';

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.API_URL}/${this.endPoint}/${id}${populateString}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          resolve(response.data.data !== undefined ? response.data.data : response.data);
        })
        .catch((error) => {
          console.error(`[${this.endPoint} Api]: `, error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  getPage(page: number): Promise<{ data: T, meta: Meta }> {
    const accessToken = window.localStorage.getItem('accessToken');
    const baseEndpoint = `${config.API_URL}/${this.endPoint}`;
    const url = `${baseEndpoint}?pagination[page]=${page}`;

    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then((response) => {
          resolve({ data: response.data.data, meta: response.data.meta });
        })
        .catch((error) => {
          console.error(`[${this.endPoint} Api]: `, error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  async getDataAndMeta(): Promise<{ data: T[], meta: Meta }[]> {
    let data = [];
    let currentPage: number = 1;
    let totalPages: number = -1;

    while (totalPages === -1 || currentPage <= totalPages) {
      let response = this.getPage(currentPage);

      if (totalPages === -1) {
        let results = await response;
        totalPages = results.meta.pagination.pageCount;
      }

      data.push(response);
      currentPage++;
    }

    return Promise.all(data);
  }

  async get(): Promise<T[]> {
    let data : T[] = [];

    const pages = await this.getDataAndMeta();

    for (const page of pages) {
      data = data.concat(page.data);
    }

    return new Promise((resolve) => {
      resolve(data);
    });
  }

  async update(id: number, data: object, formData?: FormData): Promise<T> {
    const accessToken = window.localStorage.getItem('accessToken');

    let objectData;

    if (formData) {
      objectData = formData;
      formData.append('data', JSON.stringify(data));
    } else {
      objectData = data;
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`${config.API_URL}/${this.endPoint}/${id}?populate=*`, objectData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          resolve(response.data.data !== undefined ? response.data.data : response.data);
        })
        .catch((error) => {
          console.error(`[${this.endPoint} Api]: `, error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  async create(data: object, formData?: FormData): Promise<T> {
    const accessToken = window.localStorage.getItem('accessToken');

    let objectData;

    if (formData) {
      objectData = formData;
      formData.append('data', JSON.stringify(data));
    } else {
      objectData = data;
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.API_URL}/${this.endPoint}?populate=*`, objectData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          resolve(response.data.data !== undefined ? response.data.data : response.data);
        })
        .catch((error) => {
          console.error(`[${this.endPoint} Api]: `, error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  async delete(id: number): Promise<T> {
    const accessToken = window.localStorage.getItem('accessToken');

    return new Promise((resolve, reject) => {
      axios
        .delete(`${config.API_URL}/${this.endPoint}/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          resolve(response.data.data !== undefined ? response.data.data : response.data);
        })
        .catch((error) => {
          console.error(`[${this.endPoint} Api]: `, error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

  async copy(id: number): Promise<T> {
    const accessToken = window.localStorage.getItem('accessToken');
    const data = {};

    return new Promise((resolve, reject) => {
      axios
        .put(`${config.API_URL}/${this.endPoint}/copy/${id}`, data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          resolve(response.data.data !== undefined ? response.data.data : response.data);
        })
        .catch((error) => {
          console.error(`[${this.endPoint} Api]: `, error.response);
          reject(new Error('Internal server error'));
        });
    });
  }

}