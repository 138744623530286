import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer } from '@mui/material';
import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import BriefcaseIcon from '../../icons/Briefcase';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import UserIcon from '../../icons/User';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HandshakeIcon from '@mui/icons-material/Handshake';
import GroupsIcon from '@mui/icons-material/Groups';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import i18next from 'i18next';
import { UserRoleEnum } from '../../types/role';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: 'Dashboard',
    items: [
      {
        title: i18next.t('Overview'),
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />,
        role: UserRoleEnum.Company
      },
      {
        title: i18next.t('Latest Projects'),
        path: '/dashboard/latest-projects',
        icon: <AccessTimeIcon fontSize="small" />,
        role: UserRoleEnum.Company
      },
      {
        title: i18next.t('Profil'),
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />,
        role: UserRoleEnum.Company
      }
    ]
  },

  {
    title: 'Sammlung',
    items: [
      {
        title: i18next.t('Projects'),
        path: '/dashboard/projects',
        icon: <BriefcaseIcon fontSize="small" />,
        role: UserRoleEnum.Company
      },
      {
        title: i18next.t('Contact Persons'),
        path: '/dashboard/contactperson',
        icon: <RecentActorsIcon fontSize="small" />,
        role: UserRoleEnum.Author
      },
      {
        title: i18next.t('Locations'),
        path: '/dashboard/location',
        icon: <LocationOnIcon fontSize="small" />,
        role: UserRoleEnum.Author
      },
      {
        title: i18next.t('Partner'),
        path: '/dashboard/partner/',
        icon: <HandshakeIcon fontSize="small" />,
        role: UserRoleEnum.Admin
      },
      {
        title: i18next.t('Customers'),
        path: '/dashboard/customer/',
        icon: <GroupsIcon fontSize="small" />,
        role: UserRoleEnum.Admin
      },
      {
        title: i18next.t('Users'),
        path: '/dashboard/user/',
        icon: <UserIcon fontSize="small" />,
        role: UserRoleEnum.Admin
      }
    ]
  }
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              invert
              sx={{
                height: 60,
                width: 220,
                mt: 12,
              }}
            />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'secondary.main',
            height: 'calc(100% -100px) !important',
            top: '100px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'secondary.main',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
